.loader-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
}
.loader div {
    position: absolute;
    width: 90px;
    height: 51px;
}
.loader div:nth-of-type(2) {
    transform: rotate(60deg);
}
.loader div:nth-of-type(3) {
    transform: rotate(-60deg);
}

.loader div div {
    position: relative;
    width: 100%;
    height: 100%;
}

.loader div div span {
  position: absolute;
  width: 2px;
  height: 0%;
  background: var(--cyan);
  z-index: 999999;
}

.hex-1 {
    left: 0;
    animation: load1 7.2s ease infinite;
}
.hex-2 {
    right: 0;
    animation: load2 7.2s ease .6s infinite;
}
.hex-3 {
    right: 0;
    animation: load3 7.2s ease 1.2s infinite;
}
.hex-4 {
    right: 0;
    animation: load4 7.2s ease 1.8s infinite;
}
.hex-5 {
    left: 0;
    animation: load5 7.2s ease 2.4s infinite;
}
.hex-6 {
    left: 0;
    animation: load6 7.2s ease 3s infinite;
}

@keyframes load1 {
    0% {
        bottom: 0;
        height: 0;
    }
    6.944444444% {
        bottom: 0;
        height: 100%;
    }
    50% {
        top: 0;
        height: 100%;
    }
    59.944444433% {
        top: 0;
        height: 0;
    }
}

@keyframes load2 {
    0% {
        top: 0;
        height: 0;
    }
    6.944444444% {
        top: 0;
        height: 100%;
    }
    50% {
        bottom: 0;
        height: 100%;
    }
    59.944444433% {
        bottom: 0;
        height: 0;
    }
}

@keyframes load3 {
    0% {
      top: 0;
      height: 0;
    }
    6.944444444% {
        top: 0;
        height: 100%;
    }
    50% {
        bottom: 0;
        height: 100%;
    }
    59.94444443% {
        bottom: 0;
        height: 0;
    }
}

@keyframes load4 {
    0% {
        top: 0;
        height: 0;
    }
    6.944444444% {
        top: 0;
        height: 100%;
    }
    50% {
        bottom: 0;
        height: 100%;
    }
    59.94444443% {
        bottom: 0;
        height: 0;
    }
}

@keyframes load5 {
    0% {
        bottom: 0;
        height: 0;
    }
    6.944444444% {
        bottom: 0;
        height: 100%;
    }
    50% {
        top: 0;
        height: 100%;
    }
    59.94444443% {
        top: 0;
        height: 0;
    }
}

@keyframes load6 {
    0% {
        bottom: 0;
        height: 0;
    }
    6.944444444% {
        bottom: 0;
        height: 100%;
    }
    50% {
        top: 0;
        height: 100%;
    }
    59.94444443% {
        top: 0;
        height: 0;
    }
}