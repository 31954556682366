* {
    /* This is for Safari... :facepalm: */
    z-index: 1;
}

/* Open Sans */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* The animation code */
@keyframes bg-movement {
    0% {
        background-position: 50% 50%;
    }

    33% {
        background-position: 55% 50%;
    }

    66% {
        background-position: 45% 50%;
    }

    100% {
        background-position: 50% 50%;
    }
}

@keyframes bg-scale {
    0% {
        transform: scale(1.5);
    }

    33% {
        transform: scale(1.4);
    }

    66% {
        transform: scale(1.6);
    }

    100% {
        transform: scale(1.5);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.bg-movement {
    animation: bg-movement 25s ease-in-out, bg-scale 30s ease-in-out;
    animation-iteration-count: infinite;
    background-size: cover;
    transform: scale(1.5);
}

.bg-gradient {
    background: url(./assets/images/gradient.png);
    background-size: cover;
    animation: fade-in .5s ease-out;
}

body {
    position: relative;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
}

.no-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.no-scroll::-webkit-scrollbar {
    display: none;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100%;
    }
}

.fade-in {
    opacity: 100%;
    animation-name: fadeIn;
    animation-duration: 1s;
}

@keyframes fadeOut {
    from {
        opacity: 100%;
    }

    to {
        opacity: 0;
    }
}

.fade-out {
    opacity: 0;
    animation-name: fadeOut;
    animation-duration: 1s;
}

/* Main menu */

.logo g {
    fill: currentColor;
}

.logo path {
    fill: currentColor;
}

.menu {

    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
}

.line {

    fill: none;
    stroke: white;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line-2 {

    fill: none;
    stroke: #22206B;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {

    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.line2 {

    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.line3 {

    stroke-dasharray: 30 207;
    stroke-width: 6;
}

.opened .line1 {

    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.opened .line2 {

    stroke-dasharray: 1 60;
    stroke-dashoffset: -15;
    stroke-width: 6;
}

.opened .line3 {

    stroke-dasharray: 90 207;
    stroke-dashoffset: -104;
    stroke-width: 6;
}

/* typography */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

:root {
    --blue: #22206B;
    --cyan: #8AE8DF;
    --gray: #808080;
    --lightGray: #EBECF0;
}

.body-content h1,
.body-content h2,
.body-content h3,
.body-content h4 {
    font-weight: 500;
    color: var(--blue);
    margin: 2rem 0 1rem 0;
}

.body-content h1 {
    font-size: 2.25rem;
    line-height: 1;
    margin: 0 0 2rem 0;
}

@media only screen and (min-width: 1024px) {
    .body-content h1 {
        font-size: 3rem;
    }
}

.body-content h2 {
    font-size: 1.5rem;
    line-height: 1.8rem;
    margin: 1.8rem 0 1rem 0;
}

@media only screen and (min-width: 1024px) {
    .body-content h2 {
        font-size: 2rem;
    }
}

.body-content h3 {
    font-size: 1.25rem;
    line-height: 1.8rem;
}

@media only screen and (min-width: 1024px) {
    .body-content h3 {
        font-size: 1.7rem;
    }
}

.body-content h4 {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 2rem 0 0 0;
    margin: 0 0 0.5rem 0;
}

@media only screen and (min-width: 1024px) {
    .body-content h4 {
        font-size: 1.5rem;
    }
}

.body-content p,
.body-content ul,
.body-content ol,
.body-content blockquote,
.body-content mjx-container {
    font-weight: 300;
    color: var(--blue);
    font-size: 1.2rem;
    line-height: 1.8rem;
}

.body-content p {
    margin: 0 0 1.5rem 0;
}

.body-content ul,
.body-content ol,
.body-content blockquote {
    margin: 1.5rem 0;
}

.body-content .latex-wrapper {
    width: 100%;
    display: block;
    overflow-x: auto;
}

.body-content .katex {
    font-style: italic;
    margin-bottom: 1.5rem;
    color: var(--blue);
    display: block;
}

.body-content h1 .katex,
.body-content h2 .katex,
.body-content h3 .katex,
.body-content h4 .katex {
    display: inline;
    margin-bottom: 0;
}

.body-content p .katex,
.body-content blockquote .katex,
.body-content ul .katex,
.body-content ol .katex {
    font-size: 1.2rem;
    margin-bottom: 0;
    display: inline;
}

.body-content h1 .latex-wrapper,
.body-content h2 .latex-wrapper,
.body-content h3 .latex-wrapper,
.body-content h4 .latex-wrapper,
.body-content p .latex-wrapper,
.body-content blockquote .latex-wrapper,
.body-content ul .latex-wrapper,
.body-content ol .latex-wrapper {
    width: auto;
    display: inline;
    overflow-x: auto;
}

.body-content blockquote {
    font-weight: 300;
    background: var(--lightGray);
    padding: 1rem;
}

.body-content p strong,
.body-content blockquote strong {
    font-weight: 500;
}

.body-content ul {
    list-style: disc;
    padding-left: 2rem;
}

.body-content ul ul {
    list-style: circle;
    margin: 0;
    padding-left: 2rem;
}

.body-content ul ol {
    list-style: decimal;
    margin: 0;
    padding-left: 2rem;
}

.body-content ol {
    list-style: decimal;
    padding-left: 2rem;
}

.body-content ol ol {
    list-style: decimal;
    margin: 0;
    padding-left: 2rem;
}

.body-content ol ul {
    list-style: circle;
    margin: 0;
    padding-left: 2rem;
}

.body-content a {
    border-bottom: 2px solid var(--cyan);
}

.body-content a:hover,
.body-content a:focus {
    border-bottom: none;
}

.body-content figure {
    margin: 0 0 1.5rem 0;
}

.body-content figure figcaption {
    font-weight: 300;
    color: var(--gray);
    font-size: 0.8rem;
}

.body-content hr {
    margin-bottom: 1.5rem;
}

.h-half-screen {
    height: 50vh;
}

/* notebook hex */
.notebook-article {
    width: 94px;
    height: 108px;
    mask-image: url(assets/member-hexagon.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    margin: 8px;
}

.notebook-article__border {
    width: 108px;
    height: 124px;
    background-image: url(assets/member-hexagon--border.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* Team page hex grid */
.member {
    width: 100%;
    height: 100%;
    mask-image: url(assets/member-hexagon.svg);
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.member--lg {
    width: 94px;
    height: 108px;
}

@media only screen and (min-width: 1024px) {
    .member--lg {
        width: 134px;
        height: 154px;
    }
}

@media only screen and (min-width: 1300px) {
    .member--lg {
        width: 208px;
        height: 239px;
    }
}

.member__border {
    width: 94px;
    height: 108px;
    background-image: url(assets/member-hexagon--border.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media only screen and (min-width: 1024px) {
    .member__border {
        width: 134px;
        height: 154px;
    }
}

@media only screen and (min-width: 1300px) {
    .member__border {
        width: 208px;
        height: 239px;
    }
}

:root {
    --honeyCombGap: 8px;
    --sizeOf1: calc(100% / 5);
    --margin2ndRows: calc(var(--sizeOf1) / 2);
}

.hex-grid .hex-grid__item {
    max-width: var(--sizeOf1);
    max-height: var(--sizeOf1);
    overflow: hidden;
}

.hex-grid .hex-grid__item:nth-child(1) {
    margin-left: calc(var(--sizeOf1) * 3 + var(--honeyCombGap) / 2);
}

.hex-grid .hex-grid__item:nth-child(2) {
    margin-left: calc(var(--margin2ndRows) - var(--honeyCombGap) * 2);
    margin-right: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(3) {
    margin-right: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(4) {
    margin-right: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(5) {
    margin-right: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(6) {
    margin-left: calc(var(--sizeOf1) - var(--honeyCombGap));
    margin-right: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(7) {
    margin-right: calc(var(--sizeOf1) * 2);
}

.hex-grid .hex-grid__item:nth-child(8) {
    margin-left: calc(var(--margin2ndRows) - var(--honeyCombGap));
    margin-right: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(10) {
    margin-left: var(--honeyCombGap);
}

.hex-grid .hex-grid__item:nth-child(11) {
    margin-right: var(--sizeOf1);
}

.hex-grid .hex-grid__item:nth-child(13) {
    margin-left: var(--honeyCombGap);
}

.list-active {

    border-left: 3px solid var(--cyan);
}

img[src$="centerme"] {
    display: block;
    margin: 0 auto;
}

mjx-container svg {
    display: inherit;
}

.math-display mjx-container>svg {
    width: 100%;
}

.math-display {
    overflow-x: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.math-display::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
}

.articleCaption {
    text-align: center;
}

article .tableContainer {
    overflow-x: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */

}

article .tableContainer::-webkit-scrollbar {
    display: none;
}

article table {
    min-width: 50rem;
}

article th,
article td {
    border-width: thin;
    padding: 0.5rem;
}

blockquote p {
    margin: 0 !important;
}

mjx-container {
    overflow-x: auto;
    min-width: 0 !important;
}

ul:has(mjx-container) {
    overflow-x: auto;
}